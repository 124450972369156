.searchModal {
  position: fixed;
  z-index: 111;
  width: 100%;
  background-color: #0e0e0e9e;
  top: 105px;
  border-top: 1px solid #e9e9e9;
  height: 100vh;
  padding-top: 20px;
  position: absolute;
  top: 75px;
  .searchWrapper {
    border-radius: 6px;
    background-color: #fff;
    max-width: 750px;
    margin: auto;
    .searchInput {
      display: flex;
      align-items: center;
      border: 1px solid #e9e9e9;
      border-radius: 6px;
      position: relative;
      input {
        outline: none;
        border: none;
        height: 50px;
        &:focus {
          border-color: inherit;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
      }
      span {
        padding: 10px;
        cursor: pointer;
      }
      button {
        background: var(--gradient);
        margin-right: 7px;
        padding: 5px;
        border-radius: 100px;
        font-weight: 600;
        color: #fff;
      }
      .btn-close-search {
        position: absolute;
        bottom: 25px;
        right: -20px;
        padding: 7px 7px;
      }
    }
    .searchResultList {
      padding: 0px;
      list-style: none;
      max-height: 350px;
      overflow-y: scroll;
      li {
        border-bottom: 1px solid #e9e9e9;
        background-color: #e9e9e92e;
        padding: 0 20px;
        a {
          padding: 7px;
          display: block;
          color: var(--black-light);
          &:hover,
          li svg {
            color: var(--link-color);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .searchModal {
    top: 80px;
  }
}
